import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError as observableThrowError, Subject, takeUntil } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { ApiGateway } from '../../../../api/ApiGateway.service';
import { AppState } from '../../../../reducers';
import { AccountAction } from '../../../../reducers/account/account.action';
import { getAccountSubscription } from '../../../../reducers/account/account.service';
import { AuthAction } from '../../../../reducers/auth/auth.action';
import { reformatEntityResponse, reformatListResponse } from '../../../../reducers/shared/entity.helper';
import {
  InvoicePeriodType,
  PlanType,
  SelectedSubscriptionModel,
  SubscriptionEstimatesModel,
  SubscriptionModel,
} from './subscription.model';

export interface EstimatePayload {
  plan: PlanType;
  integrationPlus?: boolean;
  country?: string;
  vatNumber?: string;
  invoicePeriod?: InvoicePeriodType;
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService implements OnDestroy {
  private destroyed$ = new Subject<void>();
  private selectedSubscription$ = new BehaviorSubject<SelectedSubscriptionModel>(null);
  public estimates: SubscriptionEstimatesModel;

  private currentSubscription: SubscriptionModel;

  public constructor(
    private api: ApiGateway,
    private store: Store<AppState>,
  ) {
    void this.store
      .select(getAccountSubscription)
      .pipe(
        filter((subscription) => !!subscription),
        takeUntil(this.destroyed$),
      )
      .subscribe((subscription: SubscriptionModel) => {
        this.currentSubscription = subscription;
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get selectedSubscriptionValue(): SelectedSubscriptionModel {
    return this.selectedSubscription$.getValue();
  }

  public getHasSetValue(): boolean {
    return !!this.selectedSubscriptionValue;
  }

  public getIntegrationPlus(): boolean {
    return this.selectedSubscriptionValue.integrationPlus;
  }

  public getInvoicePeriod(): InvoicePeriodType {
    return this.selectedSubscriptionValue.invoicePeriod;
  }

  public getCurrentSubscription(): SubscriptionModel {
    return this.currentSubscription;
  }

  public getSelectedSubscription() {
    return this.selectedSubscription$;
  }

  public setSelectedSubscription(selectedSubscription: SelectedSubscriptionModel) {
    this.selectedSubscription$.next(selectedSubscription);
  }

  public clearSelectedSubscription() {
    this.setSelectedSubscription(null);
  }

  public getSubscription() {
    return this.api.get('subscription').pipe(
      map((res) => reformatEntityResponse('Subscription', res)),
      tap((response) => this.store.dispatch(AccountAction.fetchSubscriptionSucceeded(response))),
      catchError((response) => {
        this.store.dispatch(AccountAction.fetchSubscriptionFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public getSubscriptions(): Observable<SubscriptionModel[]> {
    return this.api.get('subscriptions').pipe(map((response) => reformatListResponse('Subscription', response)));
  }

  public cancelScheduledDowngrade() {
    return this.api.post('subscriptions/cancel_scheduled_subscription', undefined).pipe(
      tap(() => {
        this.store.dispatch(AuthAction.refresh());
      }),
    );
  }

  public saveSubscription(subscriptionId: string, integrationPlus?: boolean, invoicePeriod?: InvoicePeriodType) {
    const payload = {
      Subscription: { id: subscriptionId },
      integrationPlus,
      invoicePeriod,
    };

    return this.api.put('subscription', payload).pipe(
      tap(() => {
        this.store.dispatch(AuthAction.refresh());
      }),
    );
  }

  public getEstimates(payload: EstimatePayload): Observable<SubscriptionEstimatesModel> {
    return this.api
      .post(
        'subscription/estimate',
        payload,
        // Prevent from showing toast message on error
        { headers: { ignoreMetaMessage: true } },
      )
      .pipe(
        tap((estimates) => {
          this.estimates = estimates;
        }),
      );
  }

  public getChargebeeId(): Observable<string> {
    return this.api.get('subscription/cancel').pipe(map((res) => res?.chargebee_subscription_id));
  }

  public resumeSubscription() {
    return this.api
      .put('subscription/resume', {})
      .pipe(tap(() => this.store.dispatch(AccountAction.resumeSubscriptionSuccess())));
  }
}
